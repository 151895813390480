import * as React from "react"
import { ApolloProvider, Query } from "react-apollo"
import apolloClient from "client/apolloClient"
import { SimplePage } from "components/Pages"
import { Loader } from "components/Common"
import gql from "graphql-tag"
import componentsFragments from "../../consts/componentsFragments.txt"
import fragments from "gql/fragments.js"
import schemaConfig from "../../config/schema"

const Page404 = (props) => {
  if (typeof window === `undefined`) return <Loader />
  const pageUri = `/404`
  const defaultUri = `/us/404`
  const pathParts = window.location.pathname.split(`/`)
  const countryCode =
    pathParts.length >= 2 && pathParts[1].length <= 3 ? pathParts[1] : `us`
  const langCode =
    pathParts.length >= 3 && pathParts[2].length === 2 ? `/${pathParts[2]}` : ``

  return (
    <ApolloProvider client={apolloClient}>
      <Query
        query={query}
        variables={{
          uri: pageUri,
          defaultUri,
          countryCode,
        }}
        notifyOnNetworkStatusChange
      >
        {({ loading, error, data, networkStatus }) => {
          if (error)
            return <div style={{ align: `center` }}>{`Error!: ${error}`}</div>
          if (
            (loading && networkStatus !== 4) ||
            typeof data === `undefined` ||
            (typeof data.pages === `undefined` &&
              typeof data.defaultPage === `undefined`)
          )
            return <Loader />

          const defaultLanguage = data.countries.nodes.length
            ? data.countries.nodes[0].defaultLanguage.slug
            : `en`

          const countryPages404 = data.pages.nodes.reduce((acc, page) => {
            if (
              page.language.slug === langCode ||
              page.language.slug === defaultLanguage
            )
              acc[`${page.language.slug}`] = page
            return acc
          }, {})

          const page404Data =
            countryPages404[langCode] ||
            countryPages404[defaultLanguage] ||
            data.defaultPage.nodes[0]

          const { language } = page404Data
          const country = props.pageContext.countries.reduce(
            (acc, ncountry) =>
              ncountry.slug === page404Data.country.slug ? ncountry : acc,
            {}
          )
          const baseLocaleUrl =
            language.slug !==
            (country.defaultLanguage ? country.defaultLanguage.slug : ``)
              ? `/${language.slug}`
              : ``
          const pageData = {
            locale: language.slug,
            commontext:
              country.settings && country.settings[language.slug]
                ? country.settings[language.slug]
                : {},
            home_url: `/${country.slug}${baseLocaleUrl}`,
          }
          return (
            <SimplePage
              pageContext={{
                ...props.pageContext,
                ...pageData,
                ...page404Data,
              }}
            />
          )
        }}
      </Query>
    </ApolloProvider>
  )
}

const schemaRegex = new RegExp(schemaConfig.schemaPrefix, `g`)
const pageFragment = fragments.getPageFragment(`page`, ``)
const pageQueryFields = `nodes {
  ...page
  mainpage {
    header {
      linktitle
    }
    uri
  }
  parents
  subnavi {
    slug
    title
    uri
    postId
    menuOrder
    header {
      linktitle
    }
  }
}`

const query = gql`
  ${componentsFragments.replace(schemaRegex, ``)}
  ${pageFragment.replace(schemaRegex, ``)}
  query page404($uri: String!, $countryCode: [String], $defaultUri: String!) {
    countries(where: { slug: $countryCode }) {
      nodes {
        defaultLanguage {
          slug
        }
      }
    }
    pages(
      where: {
        metaQuery: { metaArray: { value: $uri, key: "uri", compare: LIKE } }
        taxQuery: {
          taxArray: {
            terms: $countryCode
            taxonomy: COUNTRY
            operator: IN
            field: SLUG
          }
        }
      }
    ) {
      ${pageQueryFields}
    }
    defaultPage: pages(
      where: {
        metaQuery: {
          metaArray: { value: $defaultUri, key: "uri", compare: EQUAL_TO }
        }
      }
    ) {
      ${pageQueryFields}
    }
  }
`

export default Page404
